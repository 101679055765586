import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import NotFound from "../../general-components/NotFound";
import { faSadTear } from "@fortawesome/free-solid-svg-icons";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log("Error detectado:", error);
    return { hasError: true }; // 🚀 Aquí se actualiza el estado
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error atrapado:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='p-0'>
            <Row>
                <div className="login-container">
                    <div className="login-card">
                        <div className="logo">
                            <img src="/logo_color.svg" width={110} alt="Tasky Logo" />
                        </div>
                        <Row>
                            <Col className='pt-5'>
                                <NotFound iconFa={faSadTear} text={"Algo salió mal. Por favor, intenta nuevamente."}/>
                                <Button className="mt-3" onClick={() => window.location.reload()}>Recargar</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Row>
        </div>
        
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
