import moment from 'moment';
import 'moment/locale/es';
import { Badge } from "react-bootstrap";
import { faFile, faFilePdf, faFileExcel, faFileImage, faFileWord, faFileZipper } from '@fortawesome/free-solid-svg-icons';
import { logout, getUserToken } from '../controllers/user.controller';
import { GET, POST } from '../controllers/endpoints.controller';
import * as Icons from '@fortawesome/free-solid-svg-icons';

export const processNumber = function (number) {
    try {
        const formattedNumber = new Intl.NumberFormat('es-AR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(number);

        return formattedNumber;
    } catch (e) {
        return "error"
    }
}

export const correctNumberFloat = function (number) {
    if (isNaN(number)) {
        return 0;
    } else if (number.length == 0) {
        return 0;
    } else {
        return parseFloat(number);
    }
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const timeSince = (date) => {
    if (!date) {
        return null;
    }

    const now = moment();
    const dateGMT_3 = moment(date);
    const duration = moment.duration(now.diff(dateGMT_3));

    const minutes = Math.floor(duration.asMinutes());
    const hours = Math.floor(duration.asHours());
    const days = Math.floor(duration.asDays());

    if (minutes < 1) {
        return 'instantes';
    } else if (minutes < 60) {
        return `${minutes} ${minutes !== 1 ? 'minutos' : 'minuto'}`;
    } else if (hours < 24) {
        return `${hours} ${hours !== 1 ? 'horas' : 'hora'}`;
    } else {
        return `${days} ${days !== 1 ? 'días' : 'día'}`;
    }
};

export const timeDue = function (date) {
    const dateGMT_3 = moment(date).add(3, 'hours');
    const minutes = parseInt((moment.duration(moment().diff(dateGMT_3))).asMinutes());
    const hours = parseInt((moment.duration(moment().diff(dateGMT_3))).asHours());
    const days = parseInt((moment.duration(moment().diff(dateGMT_3))).asDays());

    let result = "";

    if (minutes < -60) {
        if (hours < -24) {
            //Must return days
            console.log(days)
            result = `${days} ${days != -1 ? 'días' : 'día'}`;
        } else {
            //Must return hours
            result = `${hours} ${hours != -1 ? 'horas' : 'hora'}`;
        }
    } else if (minutes > -1) {
        result = 'instantes'
    } else {
        //Must return minutes
        result = `${minutes} ${minutes != -1 ? 'minutos' : 'minuto'}`;
    }

    return result.replace('-', '');
};

export const processDate = function (date) {
    return moment(date).format("DD/MM/YYYY");
};

export const processDateCalendar = function (date) {
    return moment(date).format("YYYY-MM-DD");
};

export const processDateEvents = function (date) {
    const monthNum = moment(date).format("MM");
    let monthLet = "";

    switch (monthNum) {
        case "01":
            monthLet = "ENE";
            break;
        case "02":
            monthLet = "FEB";
            break
        case "03":
            monthLet = "MAR";
            break
        case "04":
            monthLet = "ABR";
            break
        case "05":
            monthLet = "MAY";
            break
        case "06":
            monthLet = "JUN";
            break
        case "07":
            monthLet = "JUL";
            break
        case "08":
            monthLet = "AGO";
            break;
        case "09":
            monthLet = "SEP";
            break;
        case "10":
            monthLet = "OCT";
            break;
        case "11":
            monthLet = "NOV";
            break;
        case "12":
            monthLet = "DIC";
            break;
    }
    return `${moment(date).format("DD")} ${monthLet}`;
};

export const dueWithBadge = function (d, mobile) {
    const today = moment(new Date());
    const date = moment(d)

    if (date.isAfter(today)) {
        //Not expired
        return <Badge bg='success' className={mobile == true ? '' : 'content-table status'}>Vigente</Badge>
    } else {
        return <Badge bg='danger' className={mobile == true ? '' : 'content-table status'}>Vencido</Badge>
    }
}

export const timeDueWithBadge = function (date) {
    let b;
    if (date != null) {
        const dateGMT_3 = moment(date);
        let minutes = parseInt((moment.duration(moment().diff(dateGMT_3))).asMinutes());
        let hours = parseInt((moment.duration(moment().diff(dateGMT_3))).asHours());
        let days = parseInt((moment.duration(moment().diff(dateGMT_3))).asDays());
        const formated = moment(date).format("DD/MM/YY")

        let result = "";


        if (minutes < -60) {
            if (hours < -24) {
                //Must return days
                result = `${days} ${days != -1 ? 'días' : 'día'} y ${hours % -24} ${hours != -1 ? 'horas' : 'hora'}`;

                b = <Badge bg='success' className=''>Vence el {formated} (en {result.replaceAll('-', '')})</Badge>


            } else {
                //Must return hours
                result = `${hours} ${hours != -1 ? 'horas' : 'hora'}`;
                if (isToday(dateGMT_3)) {
                    b = <Badge bg='warning' className=''>Vence hoy (en {result.replace('-', '')})</Badge>
                } else {
                    b = <Badge bg='success' className=''>Vence el {formated} (en {result.replace('-', '')})</Badge>
                }

            }
        } else if (minutes > -1) {
            //Due
            if (hours >= 24) {
                //Must return days
                result = `${days} ${days != 1 ? 'días' : 'día'}`;
                if (days >= -1) {

                    b = <Badge bg='danger' className=''>Vencido el {formated} (hace {result.replace('-', '')})</Badge>
                } else {
                    b = <Badge bg='danger' className=''>Vencido el {formated} (hace {result.replace('-', '')})</Badge>
                }

            } else {
                //Must return hours
                result = `${hours} ${hours != 1 ? 'horas' : 'hora'}`;
                if (isToday(dateGMT_3)) {
                    b = <Badge bg='danger' className=''>Vencido hoy (hace {result.replace('-', '')})</Badge>
                } else {
                    b = <Badge bg='danger' className=''>Vencido el {formated} (hace {result.replace('-', '')})</Badge>
                }

            }
        } else {
            //Must return minutes
            result = `${minutes} ${minutes != -1 ? 'minutos' : 'minuto'}`;
            b = <Badge bg='warning' className=''>Vence el {formated} (en {result.replace('-', '')})</Badge>
        }
    } else {
        b = <Badge bg='primary' className=''>Sin vencimiento</Badge>
    }
    return (b)

};

export const isToday = (date) => {
    const date1 = moment(date).format("DD/MM/YYYY");
    const date2 = moment(new Date()).format("DD/MM/YYYY")

    return (date1 == date2)
}

export const convert2Base64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}

export const getFileIcon = (fileName) => {
    if (fileName.includes('.pdf')) {
        //PDF
        return faFilePdf
    } else if (fileName.includes('.docx') || fileName.includes('.doc')) {
        //WORD
        return faFileWord
    } else if (fileName.includes('.xlsx') || fileName.includes('.xls')) {
        //EXCEL
        return faFileExcel
    } else if (fileName.includes('.png') || fileName.includes('.jpg')) {
        //IMAGE
        return faFileImage
    } else if (fileName.includes('.zip')) {
        //IMAGE
        return faFileZipper
    } else {
        return faFile
    }
}

export const getFileExtension = (fileName) => {
    return "." + fileName.split('.')[fileName.split('.').length - 1];
}

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const downloadFile = async (fileId, name) => {
    const req = await GET(`/files/byId/${fileId}`, getUserToken());
    const res = await req.json();

    if (req.status === 200) {
        const linkSource = res.file;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = name;
        downloadLink.click();
        return true;
    } else if (req.status === 403) {
        logout();
    } else {
        alert(res.message);
        return false;
    }
}

export const downloadZipFile = async (fileIds, name = "archivos") => {
    const body = {
        fileIds,
        name
    }
    console.log(body)
    const req = await POST(`/files/convertFilesToZip`, getUserToken(), body);
    const res = await req.json();

    if (req.status === 200) {
        const linkSource = res.file;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = name;
        downloadLink.click();
        return true;
    } else if (req.status === 403) {
        logout();
    } else {
        alert(res.message);
        return false;
    }
}

export const pnint = function (number) {
    try {
        const formattedNumber = new Intl.NumberFormat('es-AR', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(number);

        return formattedNumber;
    } catch (e) {
        return "error"
    }
}

export const getCroppedImg = (imageSrc, pixelCrop) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = imageSrc;
        // Usar crossOrigin si la imagen proviene de otra fuente
        image.crossOrigin = 'Anonymous';

        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Ajustar las dimensiones del canvas al tamaño del recorte
            canvas.width = pixelCrop.width;
            canvas.height = pixelCrop.height;

            // Coordenadas y dimensiones escaladas basadas en la imagen cargada
            ctx.drawImage(
                image,
                pixelCrop.x,
                pixelCrop.y,
                pixelCrop.width,
                pixelCrop.height,
                0,
                0,
                pixelCrop.width,
                pixelCrop.height
            );

            // Convertir el canvas a Base64
            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error("No se pudo crear el blob."));
                    return;
                }
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    resolve(reader.result);
                };
            }, 'image/jpeg');
        };

        image.onerror = (error) => {
            reject(new Error("No se pudo cargar la imagen para el recorte."));
        };
    });
};

export const calculateDynamicCropSize = (width, height, zoom) => {
    const scale = 1 / zoom;  // Calcula la escala inversa del zoom
    return {
        width: width * scale,
        height: height * scale
    };
}

export const getMonthsActualYear = async (showAll, schoolYear, userToken) => {
    let result = [];
    if (userToken == null) { userToken = getUserToken() }

    const req = await GET(`/classes/SchoolYear/months`, userToken);

    const res = await req.json();

    if (req.status === 200) {
        result = res;
    } else if (req.status === 403) {
        logout();
    } else {
        alert(res.message);
    }

    moment.locale('es');
    if (showAll) { return result.data }

    let qty = moment().month() + 1;

    const index = result.findIndex(month => month.num == qty);

    if (index != -1) {
        return result.slice(0, index + 1); // Incluir el objeto encontrado
    }

    return result;
};

export const getTextColorForBackground = (hexColor) => {
    if (hexColor) {
        hexColor = hexColor.replace("#", "");

        let r = parseInt(hexColor.substring(0, 2), 16);
        let g = parseInt(hexColor.substring(2, 4), 16);
        let b = parseInt(hexColor.substring(4, 6), 16);

        let brightness = (r * 299 + g * 587 + b * 114) / 1000;

        return brightness > 128 ? "black" : "white";
    } else {
        return "white";
    }
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const pluralize = (word) => {
    const pluralRules = [
        { regex: /[aeiouáéíóú]$/i, suffix: 's' }, // words ending in vowel
        { regex: /z$/i, suffix: 'ces', replace: 'z' }, // words ending in 'z'
        { regex: /ión$/i, suffix: 'iones', replace: 'ión' }, // words ending in 'ión'
        { regex: /[íú]$/i, suffix: 'es' }, // words ending in stressed vowel 'í' or 'ú'
        { regex: /[^aeiouáéíóú]$/i, suffix: 'es' }, // words ending in consonant
    ];

    for (let rule of pluralRules) {
        if (rule.regex.test(word)) {
            if (rule.replace) {
                return word.replace(new RegExp(rule.replace + '$', 'i'), rule.suffix);
            } else {
                return word + rule.suffix;
            }
        }
    }

    return word + 's';
}

export const formatName = (name, surname) => {
    const nameParts = name.split(' ');

    const middleInitial1 = nameParts[1] ? `${nameParts[1][0]}.` : '';
    const middleInitial2 = nameParts[2] ? `${nameParts[2][0]}.` : '';

    // Construct the formatted name
    const formattedName = `${nameParts[0]} ${middleInitial1} ${middleInitial2} ${surname}`.trim();

    return formattedName;
};

export const formatNameV2 = (name, surname) => {
    const nameParts = name.split(' ');

    const middleInitial1 = nameParts[1] ? `${nameParts[1][0]}.` : '';
    const middleInitial2 = nameParts[2] ? `${nameParts[2][0]}.` : '';

    // Construct the formatted name
    const formattedName = `${surname.toUpperCase()}, ${nameParts[0]} ${middleInitial1} ${middleInitial2}`.trim();

    return formattedName;
};

export const formatSurname = (surname) => {
    let result = "";

    const parts = surname.split(' ');

    let isFirst = true;

    for (let p of parts) {
        if (isFirst) {
            result += p + " ";

            if (p.length > 3) {
                isFirst = false;
            }
        }
    }

    result = result.trim();

    return result;
};

export const getIcon = (iconName) => {
    const icon = Icons[iconName];
    return icon || null;
};

export const getParams = function (param = "id") {
    const params = new URLSearchParams(window.location.search);
    if (params.has(param)) {
        return params.get(param);
    } else {
        return -1;
    }
}

export const getActualSchoolYear = async (userToken = null) => {
    const req = await GET(`/classes/getSchoolYear`, userToken == null ? getUserToken() : userToken);
    const res = await req.json();

    if (req.status === 200) {
        console.log(res)
        return res.schoolYear;
    } else if (req.status === 403) {
        logout();
    } else {
        alert(res.message);
    }
}

export const validateArgentinaPhoneNumber = (phone) => {
    /*let cleanNumber = phone.replace(/\D/g, "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "");;
    if (phone.startsWith("+54")) {
      cleanNumber = "+54" + cleanNumber.substring(2);
    }
    const regex = /^(\+54\d{10}|0\d{10}|\d{10})$/;
    return regex.test(cleanNumber);*/

    return true;
}