import React, { useEffect, useRef, useState } from 'react'
import { Form, Button, Container, Row, Col, Modal, Badge, Alert, DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faFile, faHouse, faClock, faMessage, faChalkboardTeacher, faClipboardUser, faPen, faUsers, faExclamationCircle, faAngleDown, faCog, faFileCircleCheck, faQuestionCircle, faSignOut, faBars, faTimes, faRefresh, faBook, faChartBar, faCommentAlt, faEye, faUser, faBackward } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { getFamilyMembersAmount, getFamilyMemberToken, getHasChilds, getMyNavbar, getMySchoolId, getUserRole, getUserToken, logout } from '../../controllers/user.controller';
import { formatName, getIcon } from '../../tools/tools';

export default function Navbar() {

    const [menus, setMenus] = useState([]);
    const [mobileMenus, setMobileMenus] = useState([]);
    const [isOpen, setIsOpen] = useState([]);
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState('');
    const [fatherIndex, setFatherIndex] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [isMenuOpened, setMenuOpened] = useState(false);
    const [myRole, setMyRole] = useState(getUserRole());
    const [mySchoolId, setMySchoolId] = useState(getMySchoolId())
    const brocheroId = "jcBYlxW6GEcJTgtKiQZGAm5cYPxbAi6POgfTzcyil9NHhA74tBKhDVJlf78w7w2nolOaP7z12p6UyI35CBZK69E72e3MrLgTnvGNJn9pu9NEAHj1qlYaCLohOfox0zU4tZZfbE1PecpxvHmEWVHHGAKA7z4pYOCT4VNN33yDHNviosmEskPJ2my5HyTi5WEtNNEyAjvL"

    useEffect(() => {
        //processMenus();
        changeLocation();
        navBarState();

        let m = localStorage.getItem('navBar')
        if (!m) {
            let token = myRole.includes("Familia") || myRole.includes("Padre-Madre-Tutor") ? getFamilyMemberToken() : getUserToken();
            setNavbar(token)
        } else {
            m = JSON.parse(m)
            console.log(m)
            setMenus(m.desktop)
            setMobileMenus(m.mobile)
        }

    }, []);

    const setNavbar = async (token) => {
        await getMyNavbar(token)
        let m = localStorage.getItem('navBar')
        m = JSON.parse(m)
        console.log(m)
        setMenus(m.desktop)
        setMobileMenus(m.mobile)
    }

    useEffect(() => {
        changeLocation();
        navBarState();
    }, [location])

    useEffect(() => {
        navBarState();
    }, [menus])

    //Para que se cierre el navbar si se hace clik afuera:
    const contenedorRef = useRef(null);

    useEffect(() => {
        if (isMenuOpened) {
            const handleClickOutside = (event) => {
                if (isMenuOpened && !contenedorRef.current.contains(event.target)) {
                    handleMobileMenu()
                }
            };

            document.addEventListener('click', handleClickOutside);

            return () => {
                document.removeEventListener('click', handleClickOutside);
            };
        }
    }, [isMenuOpened])

    const changeLocation = () => {
        setCurrentPage(location.pathname);
    }

    const handleMobileMenu = () => {
        setMenuOpened(!isMenuOpened);
        mobileNavBarState()
    }

    //Crea el array de estados isOpen y declara el estado del index actual.
    const navBarState = () => {
        let isOpenMomentary = new Array(menus.length).fill(false);
        const pageIndex = findIndex(currentPage);
        setFatherIndex(pageIndex.mainIndex)
        if (pageIndex.subIndex >= 0) {
            setCurrentIndex(pageIndex.subIndex);
            handleClick(pageIndex.mainIndex);
            isOpenMomentary[pageIndex.mainIndex] = !isOpenMomentary[pageIndex.mainIndex];
        } else {
            setCurrentIndex(pageIndex.mainIndex);
        }
        setIsOpen(isOpenMomentary);
    }

    const findIndex = (link) => {
        for (let i = 0; i < menus.length; i++) {
            if (menus[i].link && menus[i].link.split('?')[0] === link) {
                return { mainIndex: i, subIndex: null };
            }
            if (menus[i].NavbarSubItems && menus[i].NavbarSubItems.length > 0) {
                for (let j = 0; j < menus[i].NavbarSubItems.length; j++) {
                    if (menus[i].NavbarSubItems[j].link.split('?')[0] === link) {
                        return { mainIndex: i, subIndex: j };
                    }
                }
            } else if (menus[i].link && link.startsWith(menus[i].link.split('?')[0])) {
                return { mainIndex: i, subIndex: -1 };
            }
        }
        return { mainIndex: -1, subIndex: -1 };
    }

    // Crea el array de estados isOpen para mobileMenus y declara el estado del index actual.
    const [isOpenMobile, setIsOpenMobile] = useState(new Array(mobileMenus.length).fill(false));
    const mobileNavBarState = () => {
        let isOpenMomentaryMobile = new Array(mobileMenus.length).fill(false);
        const pageIndexMobile = findMobileIndex(currentPage);
        setFatherIndex(pageIndexMobile.mainIndex);

        if (pageIndexMobile.subIndex >= 0) {
            setCurrentIndex(pageIndexMobile.subIndex);
            handleClick(pageIndexMobile.mainIndex);
            isOpenMomentaryMobile[pageIndexMobile.mainIndex] = !isOpenMomentaryMobile[pageIndexMobile.mainIndex];
        } else {
            setCurrentIndex(pageIndexMobile.mainIndex);
        }

        setIsOpenMobile(isOpenMomentaryMobile);
    }

    const findMobileIndex = (link) => {
        for (let i = 0; i < mobileMenus.length; i++) {
            if (mobileMenus[i].link && mobileMenus[i].link.split('?')[0] === link) {
                return { mainIndex: i, subIndex: null };
            }
            if (mobileMenus[i].NavbarSubItems && mobileMenus[i].NavbarSubItems.length > 0) {
                for (let j = 0; j < mobileMenus[i].NavbarSubItems.length; j++) {
                    if (mobileMenus[i].NavbarSubItems[j].link.split('?')[0] === link) {
                        return { mainIndex: i, subIndex: j };
                    }
                }
            } else if (mobileMenus[i].link && link.startsWith(mobileMenus[i].link.split('?')[0])) {
                return { mainIndex: i, subIndex: -1 };
            }
        }
        return { mainIndex: -1, subIndex: -1 };
    }

    const handleClick = (i) => {
        const updatedIsOpen = [...isOpen];
        updatedIsOpen[i] = !updatedIsOpen[i];
        setIsOpen(updatedIsOpen);
    };

    const goToExternalUrl = (url) => {
        window.open(url, '_blank');
    };

    return (
        <>
            <div className='d-none d-lg-block'>
                <div className="navbar-vertical">
                    <div>
                        <div style={{ textAlign: 'center' }} className='mt-4'>
                            <Link to={menus != null && menus.length > 0 ? menus[0].link : '/inicio'}>
                                <img src="/logo_color.svg" width={90} alt="Logo"></img>
                            </Link>
                        </div>

                        <Row style={{ marginTop: 25 }} className=''>
                            {menus.map((menu, i) => (
                                <Row key={i} className='mt-3'>
                                    <Col className='navbar-item pe-0'>
                                        {menu.NavbarSubItems && menu.NavbarSubItems.length > 0 ?
                                            <>
                                                <div onClick={() => handleClick(i)} to={menu.link} className={i == fatherIndex ? "navbar-menu-active pe-3 navbar-drop" : "pe-3 navbar-drop"} style={{ cursor: 'pointer' }}>
                                                    <span className='navbar-icon'><FontAwesomeIcon className='navbar-icon-content' icon={getIcon(menu.icon)} /></span>
                                                    <span className='ms-2'>{menu.text}</span>
                                                    <span><FontAwesomeIcon icon={faAngleDown} className={i == fatherIndex ? "navbar-menu-active dropArrow" : "dropArrow"} /></span>
                                                </div>
                                                {isOpen[i] &&
                                                    <ul className='mb-0'>
                                                        {
                                                            menu.NavbarSubItems.map((r, j) =>
                                                                <ul className='navBarDrop' key={j}>
                                                                    <li >
                                                                        <Link className={j == currentIndex && i == fatherIndex ? "navbar-menu-active" : ""} to={r.link}>{r.text}</Link>
                                                                    </li>
                                                                </ul>)
                                                        }
                                                    </ul>}
                                            </> :
                                            <>
                                                {menu.link != null ?
                                                    <Link to={menu.link} className={i === fatherIndex ? "navbar-menu-active pe-3" : "pe-3"}><span className='navbar-icon'><FontAwesomeIcon className='navbar-icon-content' icon={getIcon(menu.icon)} /></span><span className='ms-2'>{menu.text}</span></Link>
                                                    :
                                                    <><span className='navbar-icon' onClick={() => goToExternalUrl(menu.external)}><FontAwesomeIcon className='navbar-icon-content' icon={menu.icon} /></span><span onClick={() => goToExternalUrl(menu.external)} className='ms-2'>{menu.text}</span></>
                                                }
                                            </>
                                        }
                                    </Col>
                                </Row>
                            ))}
                        </Row>
                    </div>
                    <div className="navbar-user">
                        {myRole &&
                            <>
                                {
                                    myRole.includes("Familia") || myRole.includes("Padre-Madre-Tutor") ?
                                        <Dropdown className='mb-2'>
                                            <Dropdown.Toggle className='dropdown-toggle-no-arrow' variant="outline-info" id="dropdown-basic" style={{ width: '100%' }} >
                                                <div className='align-center-vertically'>
                                                    <img className='avatar-30 me-2' src={`${localStorage.getItem('familyMemberAvatarFile')}`} alt=""></img>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '8px', alignItems: 'center' }}>
                                                        {formatName(localStorage.getItem('familyMemberName'), '')}
                                                        <Badge bg='' style={{ backgroundColor: '#CFD4E3', color: 'black', fontWeight: '500', height: 'min-content', marginBottom: '-2px' }} className=''>{localStorage.getItem('familyMemberGrade')}</Badge>
                                                    </div>
                                                </div>
                                            </Dropdown.Toggle>
                                            {getFamilyMembersAmount() > 1 || mySchoolId != brocheroId ? <Dropdown.Menu className='mb-2'>
                                                {getFamilyMembersAmount() > 1 && <>
                                                    <Dropdown.Item style={{ width: 197 }} as={Link} to={'/seleccionar-estudiante'}><FontAwesomeIcon icon={faRefresh} style={{ marginRight: 9 }} />Cambiar estudiante</Dropdown.Item>
                                                </>}
                                                {mySchoolId != brocheroId ? <Dropdown.Item style={{ width: 197 }} as={Link} to={`/estudiante/resumen/`}><FontAwesomeIcon icon={faEye} style={{ marginRight: 9 }} />Ver estudiante</Dropdown.Item> : ''}
                                            </Dropdown.Menu> : ''}
                                        </Dropdown>
                                        :
                                        ''
                                }
                            </>
                        }
                        <Dropdown>
                            <Dropdown.Toggle className='dropdown-toggle-no-arrow' variant="info" id="dropdown-basic" style={{ width: '100%' }} >
                                <p className='mb-2'><img className='avatar-10' src={`${localStorage.getItem('userAvatar')}`} alt=""></img></p>
                                <p className='m-0 fs-12'>{formatName(localStorage.getItem('userName'), localStorage.getItem('userSurname'))}</p>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='mb-2'>
                                <Dropdown.Item style={{ width: 197 }} as={Link} to={'/micuenta'}><FontAwesomeIcon icon={faUser} style={{ marginRight: 9 }} />Mi perfil</Dropdown.Item>
                                {getHasChilds() == "true" && <Dropdown.Item style={{ width: 197 }} as={Link} to={'/seleccionar-rol'}><FontAwesomeIcon icon={faRefresh} style={{ marginRight: 9 }} />Cambiar rol</Dropdown.Item>}
                                <Dropdown.Item style={{ width: 197 }} onClick={logout}><FontAwesomeIcon icon={faSignOut} style={{ marginRight: 9 }} />Salir</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>

            <div className='d-block d-lg-none' ref={contenedorRef}>
                <div className="navbar-horizontal align-center-vertically" style={{ position: 'fixed', top: '0px', width: '100%', background: 'white', zIndex: '10' }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }} className='ps-3 pe-3'>
                        <div style={{ textAlign: 'left', display: 'flex' }} className='ps-2'>
                            <Link to="/inicio">
                                <img src="/logo_color.svg" height={40} alt="Logo"></img>
                            </Link>

                        </div>

                        <div className='endRow align-center-vertically'>
                            <Button variant='primary' onClick={handleMobileMenu} style={{ height: '37px', width: '37px' }}><FontAwesomeIcon style={{ fontSize: 14 }} icon={isMenuOpened ? faTimes : faBars}></FontAwesomeIcon></Button>
                        </div>
                    </div>
                </div>
                {isMenuOpened &&
                    <div className='d-block d-lg-none' style={{ position: 'fixed', top: '60px', width: '100%', background: 'white', zIndex: '10', height: 'min-content' }}>
                        <Container className='pt-1 pb-3 ps-3 pe-2'>
                            {mobileMenus.map((menu, i) => (
                                <Row key={i} className='mt-3'>
                                    <Col className='navbar-item'>
                                        {menu.NavbarSubItems && menu.NavbarSubItems.length > 0 ?
                                            <>
                                                <div onClick={() => handleClick(i)} to={menu.link} className={i == fatherIndex ? "navbar-menu-active pe-3 navbar-drop" : "pe-3 navbar-drop"} style={{ cursor: 'pointer' }}>
                                                    <span className='navbar-icon'><FontAwesomeIcon className='navbar-icon-content' icon={getIcon(menu.icon)} /></span>
                                                    <span className='ms-2'>{menu.text}</span>
                                                    <span><FontAwesomeIcon icon={faAngleDown} className={i == fatherIndex ? "navbar-menu-active dropArrow" : "dropArrow"} /></span>
                                                </div>
                                                {isOpen[i] &&
                                                    <ul className='mb-0'>
                                                        {
                                                            menu.NavbarSubItems.map((r, j) =>
                                                                <ul className='navBarDrop' key={j}>
                                                                    <li >
                                                                        <Link className={j == currentIndex && i == fatherIndex ? "navbar-menu-active" : ""} to={r.link}>{r.text}</Link>
                                                                    </li>
                                                                </ul>)
                                                        }
                                                    </ul>}
                                            </> :
                                            <>
                                                {menu.link != null ?
                                                    <Link to={menu.link} className={i === fatherIndex ? "navbar-menu-active pe-3" : "pe-3"}><span className='navbar-icon'><FontAwesomeIcon className='navbar-icon-content' icon={getIcon(menu.icon)} /></span><span className='ms-2'>{menu.text}</span></Link>
                                                    :
                                                    <><span className='navbar-icon' onClick={() => goToExternalUrl(menu.external)}><FontAwesomeIcon className='navbar-icon-content' icon={getIcon(menu.icon)} /></span><span onClick={() => goToExternalUrl(menu.external)} className='ms-2'>{menu.text}</span></>
                                                }
                                            </>
                                        }
                                    </Col>
                                </Row>
                            ))}
                            <Row className='mt-3'>
                                <div className='mb-2' onClick={logout}>
                                    <span className='navbar-icon'><FontAwesomeIcon className='navbar-icon-content' icon={faSignOut} /></span>
                                    <span className='ms-2'>Salir</span>
                                </div>
                            </Row>
                            <Container className='pt-1 ps-3 pe-2 mt-3' >
                                {/*                                 <div className="navbar-user-mobile pe-2">
                                    <div className='navbar-user-interior'>
                                        <div style={{ width: '100%' }} >
                                            <p className='mb-2'><img className='avatar-10' src={`${localStorage.getItem('userAvatar')}`} alt=""></img></p>
                                            <p className='m-0 fs-12'>{formatName(localStorage.getItem('userName'), localStorage.getItem('userSurname'))}</p>
                                        </div>
                                    </div>
                                </div> */}
                                {myRole.includes("Familia") || myRole.includes("Padre-Madre-Tutor") ? <Dropdown className='mb-2'>
                                    <Dropdown.Toggle className='dropdown-toggle-no-arrow' variant="outline-info" id="dropdown-basic" style={{ width: '100%' }} >
                                        <div className='align-center-vertically'>
                                            <img className='avatar-30 me-2' src={`${localStorage.getItem('familyMemberAvatarFile')}`} alt=""></img>
                                            {formatName(localStorage.getItem('familyMemberName'), '')}
                                            <Badge bg='' style={{ backgroundColor: '#CFD4E3', color: 'black', fontWeight: '500', marginBottom: '-2px' }} className='ms-2'>1° A</Badge>
                                        </div>
                                    </Dropdown.Toggle>
                                    {mySchoolId != brocheroId || getFamilyMembersAmount() > 1 ? <Dropdown.Menu className='mb-2'>
                                        {getFamilyMembersAmount() > 1 && <>
                                            <Dropdown.Item style={{ width: 197 }} as={Link} to={'/seleccionar-estudiante'}><FontAwesomeIcon icon={faRefresh} style={{ marginRight: 9 }} />Cambiar estudiante</Dropdown.Item>
                                        </>}
                                        {mySchoolId != brocheroId ? <Dropdown.Item style={{ width: 197 }} as={Link} to={`/estudiante/resumen/`}><FontAwesomeIcon icon={faEye} style={{ marginRight: 9 }} />Ver estudiante</Dropdown.Item> : ''}
                                    </Dropdown.Menu> : ''}
                                </Dropdown> : ''}
                                <Dropdown>
                                    <Dropdown.Toggle className='dropdown-toggle-no-arrow' variant="info" id="dropdown-basic" style={{ width: '100%' }} >
                                        <p className='mb-2'><img className='avatar-10' src={`${localStorage.getItem('userAvatar')}`} alt=""></img></p>
                                        <p className='m-0 fs-12'>{formatName(localStorage.getItem('userName'), localStorage.getItem('userSurname'))}</p>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className='mb-2'>
                                        {getHasChilds() == "true" && <Dropdown.Item style={{ width: 197 }} as={Link} to={'/seleccionar-rol'}><FontAwesomeIcon icon={faRefresh} style={{ marginRight: 9 }} />Cambiar rol</Dropdown.Item>}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Container>
                        </Container>
                    </div>
                }
            </div>
        </>);
}