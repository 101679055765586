import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { Router } from "react-router-dom";
import ErrorBoundary from "./components/ui/error/ErrorBoundary";

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);